/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M30.14 2c-3.27 0-6.095.997-8.078 2.967C20.08 6.937 19 9.824 19 13.367V18h-6a1 1 0 00-1 1v8a1 1 0 001 1h6v19a1 1 0 001 1h8a1 1 0 001-1V28h7a1 1 0 00.992-.875l1-8A1 1 0 0037 18h-8v-4c0-1.117.883-2 2-2h6a1 1 0 001-1V3.346a1 1 0 00-.87-.99C36.248 2.237 33.058 2 30.14 2zm0 2c2.438 0 4.795.195 5.86.295V10h-5c-2.197 0-4 1.803-4 4v5a1 1 0 001 1h7.867l-.75 6H28a1 1 0 00-1 1v19h-6V27a1 1 0 00-1-1h-6v-6h6a1 1 0 001-1v-5.633c0-3.144.92-5.44 2.473-6.982C25.025 4.843 27.27 4 30.14 4z"/>'
  }
})
