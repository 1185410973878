<template>
	<router-link :to="to" class="link-img">
		<v-img :src="src" />
		<div class="fade z-1"></div>
		<div class="h3 z-2">{{label}}</div>
	</router-link>
</template>

<script>
	export default {
		name: 'link-img',
		props: ["src", "to", "label"]
	}
</script>

<style lang="scss">
	.link-img {
		display: block;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;

		&:hover {
			.v-image {
				//@include hover-image();
				filter: saturate(0.8)  brightness(1);
			}

			.fade {
				height: 50%;
				opacity: 0.7;
			}
		}

		.h3 {
			position: absolute;
			bottom: 24px;
			left: 24px;

			@include up($md) {
				bottom: 32px;
				left: 32px;
			}
		}

		.v-image {
			height: 100%;
			//@include transition-image();
			@include transition();

			filter: saturate(0.5) brightness(.9);
		}

		.fade {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 60%;
			opacity: 0.8;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #041024 100%);
			@include transition();
		}
	}
</style>
