import Config from "@/config";

let state = {

    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    requestIds: [], // sequence of request ids (hashes) to keep requests uniqueness
    navpath: [],
    isLoading: false,
    isInternalLoading: false,

    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    withBurgerMenu: false,
    isPopupLoginVisible: false,
    isPopupOfferVisible: false,
    isPopupFileRequest: false,
    withLoginRedirectToAccountHome: true,

    popupOffer: {
        item: null,
        product: null,
        image: null,
        dimImage: null,
    },

    // главное меню
    menuItems: [
        // ещвщ
        /*{name: "Shop now", to: {name:"home-shop"}},
        {name: "Product", to: {name:"home-flavours"}},
        {name: "We", to: {name:"home-story"}},
        {name: "Corporate gifts", to: {name:"home-partnership"}},
        //{name: "Special occasion", to: {name:"home-special"}},
        //{name: "Our stores", to: {name:"home-stores"}},
        {name: "Contact", to: {name:"home-contacts"}},*/
        //{name: "Terms of sale", to: {name:"terms"}},
    ],

    // настройки языка
    currentLanguageCode: "RU",
    languages: [
        {
            code: "RU",
            name: "Русский",
        },
        {
            code: "EN",
            name: "English",
        },

	],

    // params для синхронизации двух форм на странице продукта
    productParams: []
};
export default state;
