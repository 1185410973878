/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M9.775 8.881a.469.469 0 100-.937.469.469 0 000 .937z" _fill="#AFB7C3"/><path pid="1" d="M9.775 10.581v9.363m10.919 0v-6.007a3.644 3.644 0 00-3.638-3.637 3.644 3.644 0 00-3.644 3.637m0 0v6.007m0-6.007v-3.356" _stroke="#AFB7C3" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M25.313 3.438H4.688a1.25 1.25 0 00-1.25 1.25v20.625a1.25 1.25 0 001.25 1.25h20.625a1.25 1.25 0 001.25-1.25V4.688a1.25 1.25 0 00-1.25-1.25v0z" _stroke="#AFB7C3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
