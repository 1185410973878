import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// Translation provided by Vuetify (javascript)
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  theme: { dark: true },
  lang: {
    locales: { ru, en },
    current: 'ru',
  },
})
