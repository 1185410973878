<template>
    <v-app id="app">
        <transition name="route" mode="out-in">
            <!-- d-none d-lg-block" -->
            <router-view class=""/>
        </transition>
        <!-- d-flex d-lg-none -->
        <div class="d-none justify-center align-center text-center vh-100">
            <v-container>
                <h3>Извините, временно просмотр возможен только<br/>на разрешении 1920 пкс и больше.</h3>
            </v-container>
        </div>
    </v-app>
</template>

<script>

export default {
    name: 'App',

    data: () => ({

    }),
    computed: {

    },
    methods: {
        onResize() {
            //console.log("App onResize");

            this.$store.commit('updateWindowWidth');
            this.$store.commit('updateWindowHeight');

            // на iOS чтобы попадало во весь экран на телефоне 100vh:
            //нужно добавить css переменную vh в main.scss:
            //:root {
            //--vh: 1vh;
            //}
            //после вместо vh использовать var(--vh, 1vh)
            //например всето 100vh = calc(var(--vh, 1vh)*100)
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
    mounted() {

        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    }
}
</script>

<style lang="scss">

// todo revisit all below

#app {
    //	overflow: hidden; todo find fix for sticky
    min-width: 320px;

    p {
        // todo ?
        margin-bottom: 0;
    }
}

.text-small {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
}


.svg-hover {
    cursor: pointer;

    path {
        @include transition();
    }

    &:hover {
        path {
            fill: $primary-1;
            opacity: 1;
        }
    }
}

.svg-hover2 {
    cursor: pointer;
    fill: none;

    path {
        @include transition();
    }

    &:hover {
        path {
            stroke: $primary-1;
            opacity: 1;
        }
    }
}

// todo
.text-white {
    color: $white;
}

// todo
.text-white-blue {
    color: $primary-2;
}

.section {
    &--padding {
        padding: 50px 0;
        @include up($sm) {
            padding: 60px 0;
        }
        @include up($md) {
            padding: 50px 0;
        }

        &-bottom {
            padding-bottom: 60px;
            @include up($md) {
                padding-bottom: 50px;
            }
        }
    }

    &--large-padding {
        &-t {
            padding-top: 100px;
            @include up($sm) {
                padding-top: 120px;
            }
            @include up($md) {
                padding-top: 100px;
            }
        }

        &-b {
            padding-bottom: 100px;
            @include up($sm) {
                padding-bottom: 120px;
            }
            @include up($md) {
                padding-bottom: 100px;
            }
        }
    }

    &--padding-intro {
        padding: 0 0 50px;
        @include up($md) {
            padding: 60px 0 90px;
        }

    }


    &--small-padding {
        padding: 45px 0;
        @include up($sm) {
            padding: 64px 0;
        }

        @include up($md) {
            padding: 80px 0;
        }


    }
}

.header-padding {
    padding-bottom: 64px;
    @include up($md) {
        padding-bottom: 50px;
    }
}

/*
* стандартный отсуп снизу, используется для любого элемента
// todo needed?
*/
.margin-bottom-main {
    margin-bottom: 40px !important;

    @include up($md) {
        margin-bottom: 48px !important;
    }

}

</style>
