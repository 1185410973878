/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M22.429 23H7.57a.616.616 0 00-.404.146A.47.47 0 007 23.5c0 .133.06.26.167.354a.616.616 0 00.404.146H22.43a.615.615 0 00.404-.146A.47.47 0 0023 23.5a.47.47 0 00-.167-.354.615.615 0 00-.404-.146zM15.027 21l5.84-6.246a.615.615 0 00-.028-.8.535.535 0 00-.368-.171.529.529 0 00-.38.14l-4.513 4.822V6.59a.61.61 0 00-.162-.417.533.533 0 00-.389-.172.533.533 0 00-.389.172.61.61 0 00-.161.417v12.156l-4.508-4.821a.557.557 0 00-.179-.147.523.523 0 00-.629.114.626.626 0 00-.106.673c.033.072.08.137.137.19L15.027 21z" _fill="#AFB7C3"/>'
  }
})
