/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-2': {
    width: 23,
    height: 19,
    viewBox: '0 0 23 19',
    data: '<path pid="0" d="M20.641 8.89L12.447.854l.9-.855L23 9.5 13.347 19l-.9-.855 8.194-8.034H0V8.889h20.641z"/>'
  }
})
