/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'submit-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 12H4m9-7l7 7-7 7V5z" _stroke="#E1E5EC" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
