/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'line': {
    width: 30,
    height: 1,
    viewBox: '0 0 30 1',
    data: '<path pid="0" _fill="#4FC9FF" d="M0 0h30v1H0z"/>'
  }
})
