/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.778 7c0-3.866-3.482-7-7.778-7C3.704 0 .222 3.134.222 7 .222 13.369 8 20 8 20s7.778-6.687 7.778-13zm-3.89.778a3.889 3.889 0 11-7.777 0 3.889 3.889 0 017.778 0z" _fill="#4FC9FF"/>'
  }
})
