/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cart': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M24.73 16.356l1.891-7.009H9.098l1.891 7.009c.122.451.373.826.703 1.088.331.26.746.41 1.198.41h9.938a1.92 1.92 0 001.198-.41c.331-.261.582-.636.703-1.088zm-1.902 3.957c.621 0 1.184.262 1.59.687.408.424.66 1.01.66 1.657 0 .646-.252 1.232-.66 1.657-.406.424-.969.686-1.59.686a2.2 2.2 0 01-1.59-.686 2.388 2.388 0 01-.659-1.657c0-.647.252-1.233.659-1.657.407-.425.969-.687 1.59-.687zm.75 1.563a1.034 1.034 0 00-1.5 0c-.19.2-.309.476-.309.78 0 .306.119.581.31.782a1.035 1.035 0 001.498 0c.192-.2.311-.476.311-.781 0-.305-.118-.581-.31-.78zM12.89 20.313c.62 0 1.183.262 1.59.687.407.424.659 1.01.659 1.657 0 .646-.252 1.232-.659 1.657a2.2 2.2 0 01-1.59.686 2.198 2.198 0 01-1.59-.686 2.385 2.385 0 01-.66-1.657c0-.647.252-1.233.66-1.657.406-.425.969-.687 1.59-.687zm.75 1.563a1.034 1.034 0 00-1.5 0c-.19.2-.31.476-.31.78 0 .306.12.581.31.782.192.199.457.322.75.322.292 0 .557-.123.75-.323.19-.2.309-.475.309-.78 0-.305-.118-.581-.31-.78zM7.73 8.856L7.43 7.74a2.055 2.055 0 00-.703-1.089 1.918 1.918 0 00-1.198-.41H3.595A.607.607 0 013 5.62c0-.343.266-.62.595-.62h1.934c.716 0 1.38.24 1.914.662a3.304 3.304 0 011.131 1.742l.19.703h18.642c.053 0 .106.008.16.024.316.092.5.434.412.763l-2.104 7.795a3.295 3.295 0 01-1.131 1.742 3.069 3.069 0 01-1.915.663h-9.937c-.716 0-1.38-.24-1.914-.663a3.298 3.298 0 01-1.131-1.742L7.753 8.933a.64.64 0 01-.021-.078v.001z" _fill="#E1E5EC"/>'
  }
})
