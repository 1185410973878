import config from "../config";
import router from "@/router";

const getters = {

	windowHeight(state) {
		return state.windowHeight;
	},
	/*
	* isWindowXS работает только для моб экрана, остальные геттеры по нарастанию
	* */
	isWindowXS(state) {
		return state.windowWidth < config.WIDTH_SM;
	},
	isWindowSM(state) {
		return state.windowWidth >= config.WIDTH_SM;
	},
	isWindowMD(state) {
		return state.windowWidth >= config.WIDTH_MD;
	},
	isWindowLG(state) {
		return state.windowWidth >= config.WIDTH_LG;
	},
	isWindowXL(state) {
		return state.windowWidth >= config.WIDTH_XL;
	},

	user(state) {
		return state.session?.user;
	},

	currentLanguage(state) {
		return state.languages.find(el => el.code === state.currentLanguageCode);
	}
};
export default getters;
