/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M26.25 23.75h-15m-7.5-17.5h5-5zm22.5 0h-12.5 12.5zM3.75 15h15-15zm22.5 0h-2.5 2.5zm-22.5 8.75h2.5-2.5zM11.25 8.75a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM21.25 17.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM8.75 26.25a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" _stroke="#AFB7C3" stroke-linecap="round"/>'
  }
})
