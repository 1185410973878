/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.256 21.696c4.278-.625 7.223-.571 11.507.022a1.298 1.298 0 011.112 1.293c0 .3-.103.591-.29.819a38.3 38.3 0 01-.997 1.17h1.65c.105-.124.209-.25.314-.378.371-.455.573-1.023.573-1.61a2.548 2.548 0 00-2.19-2.531c-4.386-.607-7.45-.665-11.86-.021-1.28.187-2.2 1.294-2.2 2.569 0 .565.184 1.125.534 1.578l.306.394h1.61a36.093 36.093 0 01-.926-1.157 1.34 1.34 0 01-.274-.816c0-.673.484-1.237 1.13-1.332zM15 15.625a3.75 3.75 0 100-7.499 3.75 3.75 0 000 7.499zm0 1.25a5 5 0 100-10 5 5 0 000 10z" _fill="#E1E5EC"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15 26.25c6.213 0 11.25-5.037 11.25-11.25S21.213 3.75 15 3.75 3.75 8.787 3.75 15 8.787 26.25 15 26.25zm0 1.25c6.904 0 12.5-5.596 12.5-12.5S21.904 2.5 15 2.5 2.5 8.096 2.5 15 8.096 27.5 15 27.5z" _fill="#E1E5EC"/>'
  }
})
