<template>
	<div class="selector" :class="{active:active,disabled:disabled,single:single,bright:bright}">
		<!--<v-select v-model="internalValue"
				  :label="label"
				  :items="items"
				  multiple
				  dense
				  @change="onChange"/>-->
		<div class="hover" @click="onActivate"></div>
		<v-text-field v-model="text"
					  :label="label"
					  :disabled="disabled"
					  readonly
					  multiple
					  dense
					  hide-details/>
		<svgicon name="arrow-right"/>

		<div class="selector__items">
			<ul class="glass">
				<li v-for="(item, index) of items"
					:key="'li'+index"
					@click.stop.prevent="onSelect(item)"
					:class="{active:isSelectedItem(item)}">
					<svgicon v-if="isSelectedItem(item)" name="checkbox-on"/>
					<svgicon v-else name="checkbox-off"/>
					<span>{{item.text}}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {scrollTo, debounce} from "@/utils/utils";
	import Config from "@/config";

	export default {
		name: 'selector',
		props: {
			value: {},
			label: {},
			items: {},
			disabled: {
				type: Boolean,
				default: false
			},
			single: {
				type: Boolean,
				default: false
			},
			bright: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				internalValue: [],
				text: "",
				active: false,
				isMeActive: false,
			}
		},
		watch: {
			value: {
				immediate: true,
				handler() {
					this.internalValue = this.value || [];
					/*this.internalValue = this.value.map(el => ({
						...el
					}));*/
				}
			},
			internalValue: {
                immediate: true,
                handler() {
                    this.text = this.items.filter(el => this.internalValue.some(v => v === el.value)).map(item => item.text).join(", ");
                }
			}
		},
		methods: {
			onChange() {
				this.$emit('input', this.internalValue);
			},
			onActivate() {
				this.active = !this.active;
				if (this.active) this.isMeActive = true;
				setTimeout(() => {
					this.isMeActive = false;
				}, 150);
			},
			onDeactivate() {
				if (this.isMeActive) return;
				this.active = false;
				//document.removeEventListener("click", this.onDeactivate);
			},
			onSelect(item) {
				const index = this.internalValue.findIndex(v => v === item.value);
				if (this.single) {
					if (index === -1) this.internalValue.splice(0, this.internalValue.length, item.value);
					else this.internalValue.splice(0, this.internalValue.length);
				} else {
					if (index === -1) this.internalValue.push(item.value);
					else this.internalValue.splice(index, 1);
				}

				this.$emit("input", this.internalValue);
				this.$emit("change", this.internalValue);
				this.closeDebounced();
			},
			isSelectedItem(item) {
				return this.internalValue.some(el => el === item.value);
			},
			closeDebounced: debounce(function () {
				this.onDeactivate();
			}, Config.DEBOUNCE_INPUT_TIMEOUT_LONG),
		},
		mounted() {
			//this.internalValue = this.value;
			document.addEventListener("click", this.onDeactivate);
		},
		destroyed() {
			document.removeEventListener("click", this.onDeactivate);
		}
	}
</script>

<style lang="scss">
	.selector {
		//display: inline-block;
		position: relative;
		//padding: 0 35px;
		//height: 67px;
		//border: 1px solid $mid-grey;
		color: $mid-grey;
		@include transition();

		&:not(.disabled):hover {
			.v-text-field {
				.v-input__slot {
					&::before {
						border-color: $blue-light !important;
					}
				}

				input, label {
					color: $blue-light;
				}
			}

			& > svg {
				fill: $blue-light;
			}
		}

		.hover {
			cursor: pointer;
			position: absolute;
			z-index: 2;
			left: 0;
			top: 0;
			width: 100%;
			height: 36px;
			//background: red;
			//opacity: 0;
		}

		&.active {
			.selector__items {
				display: block;
			}
		}

		&.disabled {
			opacity: 0.7;

			.hover {
				cursor: default;
			}
		}

		&.single {
			ul {
				li {
					svg {
						opacity: 0;
					}

					&.active {
						svg {
							opacity: 1;
							fill: $white;
						}
					}
				}
			}
		}

		&.bright {
			.selector__items {
				background: $selector-bg--bright;

				li {
					color: $light-grey;
					border-bottom: 1px solid $mid-grey-opacity;

					&:hover {
						color: $white;

						svg {
							opacity: 1;
							fill: $white;
						}
					}

					&.active {
						color: $white;

						svg {
							opacity: 1;
							fill: $white;
						}
					}
				}

			}
		}

		input {
			cursor: pointer;
			height: 32px;
			padding-right: 32px !important;
			/*z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			display: block;
			width: 100%;
			height: 100%;*/
		}

		& > svg {
			position: absolute;
			right: 8px;
			top: 6px;
			width: 12px;
			height: 12px;
			fill: $white;
			transform: rotate(90deg);
			@include transition();
		}

		ul {
			li {
				position: relative;
				cursor: pointer;
				padding: 8px 20px !important;
				border-bottom: 1px solid $mid-grey-opacity;
				display: flex;
				align-items: flex-start;
				font-size: 16px;
				//color: $blue-light;
				@include transition();
				//filter: brightness(2);

				&:hover {
					color: $blue-light;

					svg {
						opacity: 1;
						fill: $blue-light;
					}
				}

				&.active {
					color: $white;

					svg {
						opacity: 1;
						fill: $white;
					}
				}

				svg {
					flex-shrink: 0;
					width: 16px;
					height: 16px;
					/*left: 10px;
					top: 20px;
					right: unset;*/
					margin-top: 4px;
					margin-right: 16px;
					fill: $mid-grey-opacity;

					//opacity: 0.5;
					@include transition();
				}
			}
		}

		&__items {
			z-index: 4;
			position: absolute;
			width: calc(100% + 3px);
			top: 32px;
			left: 0;
			display: none;
			max-height: 328px;
			//overflow-x: hidden;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-track {
				background-color: transparent;
				position: relative;
				left: -2px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $blue-light;
			}
		}

		& + .selector {
			margin-left: 20px;
		}
	}
</style>
