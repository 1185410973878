/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M6.429 20.714h17.135M6.43 9.286H23.57 6.43zM6.426 15h17.138H6.426z" _stroke="#E1E5EC" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
