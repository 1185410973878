/* eslint-disable */
require('./arrow-down')
require('./arrow-right-2')
require('./arrow-right')
require('./cart')
require('./checkbox-off')
require('./checkbox-on')
require('./cross')
require('./download')
require('./earth')
require('./exit')
require('./facebook')
require('./hamburger')
require('./instagram')
require('./line')
require('./linkedin')
require('./location')
require('./logo')
require('./playnext')
require('./profile')
require('./search')
require('./settings')
require('./submit-arrow')
require('./vk')
require('./youtube-play')
require('./youtube')
