/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 15,
    height: 8,
    viewBox: '0 0 15 8',
    data: '<path pid="0" d="M.857.884L7.93 7.955 15 .884 14.116 0 7.93 6.188 1.74 0 .857.884z" _fill="#AFB7C3"/>'
  }
})
