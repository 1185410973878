/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M16.664 21.183V18.07c2.09.32 2.752 1.965 4.083 3.113h3.394a13.763 13.763 0 00-3.703-4.908c1.218-1.678 2.512-3.258 3.145-5.653h-3.085c-1.209 1.833-1.846 3.98-3.834 5.395v-5.395h-4.477l1.07 1.322v4.71c-1.735-.2-2.907-3.374-4.177-6.032H5.86c1.171 3.59 3.637 11.47 10.804 10.561v0z" _stroke="#AFB7C3" stroke-width=".75" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
