/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M12.6 22.8c-4.985 0-9-4.149-9-9.3 0-5.15 4.015-9.3 9-9.3s9 4.15 9 9.3c0 5.151-4.015 9.3-9 9.3zm0-17.169c-4.223 0-7.615 3.505-7.615 7.87 0 4.363 3.392 7.868 7.615 7.868 4.223 0 7.615-3.505 7.615-7.869S16.823 5.631 12.6 5.631z" _fill="#E1E5EC"/><path pid="1" d="M19.58 19.2l6.22 6.22-.98.98-6.22-6.22.98-.98z" _fill="#E1E5EC"/>'
  }
})
