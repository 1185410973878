import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import routes from '@/router/routes';
import {mergeObjects, scrollTo} from "@/utils/utils";
import {md5} from "@/utils/string";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

/*const scrollTo = (hash = null, offset = 0, behavior = 'smooth') => {
	let top = 0;

	if (hash) {
		hash = hash.match(/^\#.+/) ? hash.substring(1) : hash;
		const el = document.getElementById(hash);
		//console.log('Rect:', el.getBoundingClientRect());
		if (el) top = el.getBoundingClientRect().top + window.scrollY + offset;
		else {
			console.warn("Scroll to element not found: " + hash);
			return;
		}
	}

	window.scrollTo({
		behavior,
		left: 0,
		top
	});
};*/

router.beforeEach((to, from, next) => {
	// если мы навигируем только по hash-ам на том же роуте - просто скроллим
	if (from.meta?.api === to.meta?.api && to.meta?.hash) {
		scrollTo(to.meta?.hash);
		//next();
		//router.replace(to);
		store.state.withBurgerMenu = false;
		return;
	}

	store.state.isPopupLoginVisible = false;
	store.state.isPopupOfferVisible = false;

	// we need to fetch (1) user & (2) page data
	const ps = [];

	if ( from.meta?.isInternal && to.meta?.isInternal ) store.state.isInternalLoading = true;
	else store.state.isLoading = true;

	// 1. загружаем данные юзeh-сессии (некэшируемые), они не зависят от роута:
	if ( !to.meta?.hasSession ) {	// meta.hasSession means request to meta.api wil return session object and separate request to session is not required
		ps.push(new Promise((resolve, reject) => {
			store.dispatch('fetchSession').then(() => {
				// user data fetched
				// здесь не вызываем next() - его вызовем ниже после получения обои запросов
				resolve();
			}).catch(() => {
				console.warn("User data is empty...");

				// todo
				//reject();
				resolve();
			});
		}));
	}

	// 2. загружаем данные текущего роута (кэшируемые), в т.ч. common данные:
	if (to.meta?.api) {
		ps.push(new Promise((resolve, reject) => {
			let params = mergeObjects({}, {action: to.meta.api, method: to.meta.method, params: to.params}, to.meta);
			params.requestId = md5(to.fullPath);
			console.log("params", params);
			store.dispatch('fetchPage', params)
				.then(() => {
					// page data fetched
					// здесь не вызываем next() - его вызовем ниже после получения обои запросов
					resolve();
				})
				.catch((err) => {
					console.error("API error: ", err);
					reject();
				});
		}));
	}

	setTimeout(()=>{
		Promise.all(ps)
			.then(values => {
				console.log("All fetched");
				next();
			})
			.catch(err => {
				// todo

				/*if (err.code === Config.API_EXCEPTION_NOT_FOUND) {
				  router.replace({name: 'error404'});
				} else if (err.code == Config.ERROR_CODE_FORBIDDEN || err.code == Config.API_EXCEPTION_FORBIDDEN) {
				  router.replace({name: 'login'});
				} else if (err.code === Config.API_EXCEPTION_SUSPENDED) {
				  router.replace({name: 'report-accepted'});
				} else {
				  router.replace({name: 'error500'});
				}*/
			});
	},250);	// чуть больше половины от transition-long() - ждем затухания перед загрузкой

});

router.afterEach((to, from) => {
	if ( from.meta?.isInternal && to.meta?.isInternal ) store.state.isInternalLoading = false;
	else store.state.isLoading = false;

	setTimeout(() => {
		if (from?.path && to?.path !== from.path) {

      if ( to?.meta.routingId && from?.meta?.routingId === to?.meta.routingId ) {
        // scrolling to some anchor on the new page + some pause before scrolling to allow transition
        scrollTo(to.meta.routingId, -72, 'smooth')
      }
      else {
        // by default - scrolling to top of the new page
        scrollTo("app", 0, "instant");
      }

			/*const anchor = (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
			if (anchor) {
				// yet more pause to allow dom-model to appear
				setTimeout(() => scrollTo(anchor, -120, 'smooth'), 500);
			}*/
		}
		store.state.withBurgerMenu = false;

	}, 25);	// todo проверить таймаут
});

export default router
