/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M26.945 8.996a3.129 3.129 0 00-2.208-2.208c-1.446-.558-15.458-.832-19.523.016a3.129 3.129 0 00-2.208 2.209c-.653 2.864-.702 9.057.016 11.985a3.129 3.129 0 002.209 2.208c2.864.66 16.481.752 19.522 0a3.129 3.129 0 002.208-2.208c.696-3.12.746-8.93-.016-12.002v0z" _stroke="#AFB7C3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M19.104 14.997l-6.529-3.745v7.49l6.53-3.745z" _stroke="#AFB7C3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
