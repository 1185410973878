<template>
	<v-btn
			class="btn"
			:class="btnClass"
			:disabled="disabled"
			:loading="busy" @click="$emit('click')">
		<slot>
			<!--Каталог продукции-->{{common.texts.COMMON_CATALOG_BTN.NAME}}
		</slot>
	</v-btn>
</template>

<script>
	export default {
		name: 'btn-main',
		props: {
			bigMD: {
				type: Boolean,
				default: false
			},
			block: {
				type: Boolean,
				default: false
			},
			borderlessMd: {
				type: Boolean,
				default: false
			},
			compact: {
				type: Boolean,
				default: false
			},
			busy: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			btnClass() {
				const c = {
					'big-md': this.bigMD,
					'block': this.block,
					'borderless-md': this.borderlessMd,
					compact: this.compact
				};
				return c;
			},
            common() {
                return this.$store.state.common;
            },
		}
	}
</script>

<style lang="scss">
	.btn.v-btn {
		font-family: $header-font-family;
		padding: 0 35px !important;
		display: inline-block !important;
		font-weight: 300 !important;
		font-size: 20px !important;
		text-transform: uppercase !important;
		color: $white !important;
		height: 70px !important;
		border: 1px solid $mid-grey !important;
		border-radius: 0 !important;
		background: rgba(1, 9, 24, 0.5) !important;
		box-shadow: none !important;
		@include transition();

		@include up($md) {
			font-size: 18px !important;
			letter-spacing: 0.03em !important;
			height: 64px !important;
			.v-btn__content {
				line-height: 60px;
			}
		}

		&:before {
			background: rgba(1, 9, 24, 0.5) !important;
		}

		&:hover {
			background: $primary-2 !important;
		}


		.svg-icon {
			width: 8.5px;
			height: 15px;
			color: $white !important;
			margin-left: 23px !important;

			@include up($md) {
				margin-top: 0;
				width: 16px;
				height: 37px;
				margin-left: 36px !important;
			}
		}

		&.borderless-md {
			border: 1px solid $mid-grey !important; // до md в любом случаи с бордюром
			background: transparent !important;

			border-radius: 0 !important;
			font-size: 18px !important;
			@include up($md) {
				border: 1px solid transparent !important;
				font-size: 24px !important;
			}

			path {
				@include transition();
			}

			&:hover {
				border: 1px solid $white !important;
				color: $primary-2 !important;

				.svg-icon {
					path {
						fill: $primary-2 !important;
					}
				}
			}
		}

		&.block {
			display: block !important;
			width: 100% !important;
		}

		&.compact {
			height: 50px !important;

			.v-btn__content {
				line-height: 48px;
			}
		}

		&.big-md {
			padding: 0 17.5px !important;
			@include up($sm) {
				height: 56px !important;
				padding: 0 26.5px !important;
			}
			@include up($md) {
				padding: 0 30px !important;
				height: 80px !important;
			}
		}

	}
</style>
