/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 17,
    height: 29,
    viewBox: '0 0 17 29',
    data: '<path pid="0" d="M1.969 28.885L16.11 14.743 1.97.6.2 2.368l12.375 12.375L.201 27.118l1.768 1.767z" _fill="#AFB7C3"/>'
  }
})
