import Vue from 'vue';

// default tags
import btn from '@/components/btn';
import selector from '@/components/selector';
import linkImg from '@/components/link-img';

Vue.component('btn', btn);
Vue.component('selector', selector);
Vue.component('link-img', linkImg);
