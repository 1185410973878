import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
	{
		path: '/', component: () => import('../layout/layout-main'),
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import('@/views/home'),
				meta: {api: "HomeController", withoutNavPath: true}
			},
			{
				path: '/catalog',
				name: 'catalog',
				component: () => import("@/views/catalog"),
				meta: {api: "CatalogController"}
			},
			{
				path: '/catalog/:code',
				name: 'category',
				component: () => import('@/views/category'),
				meta: {api: "CategoryController"}
			},
			{
				path: '/catalog/:categoryCode/:code',
				name: 'subcategory',
				component: () => import('../views/subcategory'),
				meta: {api: "SubcategoryController"}
			},
			{
				path: '/catalog/:categoryCode/:subcategoryCode/:code',
				name: 'product',
				component: () => import('../views/product'),
				meta: {api: "ProductController"}
			},
			{
				path: '/search',
				name: 'search',
				component: () => import('@/views/search'),
				meta: {api: "SearchController"}
			},
			{
				path: '/projects',
				name: 'projects',
				component: () => import('../views/projects'),
				meta: {api: "ProjectsController"}
			},
			{
				path: '/projects/:code',
				name: 'project',
				component: () => import('../views/project'),
				meta: {api: "ProjectController"}
			},
			{
				path: '/science',
				name: 'science',
				component: () => import('../views/science'),
				children: [
					{
						path: '/science/approach',
						name: 'science-approach',
						component: () => import('../views/science/science-approach'),
						meta: {api: "ScienceController", isInternal: true, routingId: "science-info"}
					},
					{
						path: '/science/laboratory',
						name: 'science-laboratory',
						component: () => import('../views/science/science-laboratory'),
						meta: {api: "LaboratoryController", isInternal: true, routingId: "science-info"}
					},
					{
						path: '/science/students',
						name: 'science-students',
						component: () => import('../views/science/science-students'),
						meta: {api: "StudentsController", isInternal: true, routingId: "science-info"}
					}
				]
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('../views/about'),
				children: [
					{
						path: '/about/technology',
						name: 'about-technology',
						component: () => import('../views/about/about-technology'),
						meta: {api: "AboutController", /*params:{code:'about'},*/ isInternal: true, routingId: 'about-info'}
					},
					{
						path: '/about/ecology',
						name: 'about-ecology',
						component: () => import('../views/about/about-ecology'),
						meta: {api: "EcoController", isInternal: true, routingId: 'about-info'}
					},
					{
						path: '/about/certificates',
						name: 'about-certificates',
						component: () => import('../views/about/about-certificates'),
						meta: {api: "CertificateController", isInternal: true, routingId: 'about-info'}
					}
				]
			},
			{
				path: '/dealers',
				name: 'dealers',
				component: () => import('../views/dealers'),
				meta: {api: "DealersController"}
			},
			{
				path: '/contacts',
				name: 'contacts',
				component: () => import('../views/contacts'),
                meta: {api: "ContactsController"}

			},
			{
				path: '/basket',
				name: 'basket',
				component: () => import('@/views/basket'),
				meta: {api: "BasketController", hasSession: true}
			},
			{
				path: '/order/:id',
				name: 'order',
				component: () => import('@/views/order'),
				meta: {api: "OrderController", hasSession: true, withoutNavPath: true}
			},
			{
				path: '/registration',
				name: 'registration',
				component: () => import('@/views/registration'),
				meta: {api: "RegistrationController"}
			},
            {
                path: '/career',
                name: 'career',
                component: () => import('@/views/career'),
                meta: {api: "VacancyListController"}
            },
            {
                path: '/career/:id',
                name: 'vacancy',
                component: () => import('@/views/vacancy'),
                meta: {api: "VacancyItemController"},
                props: true,
            },
			{
				path: '/account', component: () => import('../layout/layout-account'),
				children: [
					{
						path: '/account/home',
						name: 'account-home',
						component: () => import('../views/account/account-home'),
						meta: {api: "ProfileController", isInternal: true, hasSession: true}
						// meta.hasSession means request to meta.api wil return session object and separate request to session is not required
					},
					{
						path: '/account/profile',
						name: 'account-profile',
						component: () => import('../views/account/account-profile'),
						meta: {api: "ProfileController", isInternal: true, hasSession: true}
					},
					{
						path: '/account/orders',
						name: 'account-orders',
						component: () => import('../views/account/account-orders'),
						meta: {api: "OrdersController", isInternal: true, hasSession: true}
					},
					/*{
						path: '/account/edit-payment',
						name: 'account-edit-payment',
						component: () => import('../views/account/account-edit-payment'),
						meta: {isInternal: true},
					},*/
					{
						path: '/account/addresses',
						name: 'account-addresses',
						component: () => import('../views/account/account-addresses'),
						meta: {api: "UserAddressesController", isInternal: true, hasSession: true},
					},
					{
						path: '/account/addresses/:id',
						name: 'account-address',
						component: () => import('../views/account/account-address'),
						meta: {api: "UserAddressController", isInternal: true, hasSession: true},
						props: true
					},
					{
						path: '/account/addresses/new',
						name: 'account-address-new',
						component: () => import('../views/account/account-address'),
						meta: {api: "RestrictedController", isInternal: true, hasSession: true},
					},
					{
						path: '/account/projects',
						name: 'account-projects',
						component: () => import('../views/account/account-projects'),
						meta: {api: "UserProjectsController", isInternal: true, hasSession: true}
					},
					{
						path: '/account/projects/:id',
						name: 'account-project',
						component: () => import('../views/account/account-project'),
						meta: {api: "UserProjectController", isInternal: true, hasSession: true},
						props: true,
					},
					{
						path: '/account/projects/new',
						name: 'account-project-new',
						component: () => import('../views/account/account-project'),
						meta: {api: "RestrictedController", isInternal: true, hasSession: true},
					},
					{
						path: '/account/support',
						name: 'account-support',
						component: () => import('../views/account/account-support'),
						meta: {api: "ProfileController", isInternal: true, hasSession: true},
					},
				]
			},
		]
	},


];
export default routes;










