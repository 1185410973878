/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'exit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16 13v-2H7V8l-5 4 5 4v-3h9z" _fill="#AFB7C3"/><path pid="1" d="M10 8.667V2h12v20H10v-6.667" _stroke="#AFB7C3"/>'
  }
})
