export default {
    API_DEFAULT_ACTION: '/api/Form',

    STORAGE_AUTH_TOKEN: "auth",
    STORAGE_PROP_ORDER_HASH: "orderHash",
    STORAGE_PROP_USER_ID: "userId",

    ERROR_CODE_FORBIDDEN: "403",
    ERROR_CODE_NOT_FOUND: "404",
    ERROR_CODE_EXISTS: "EXISTS",
    ERROR_CODE_WRONG_PARAMS: "WRONG_PARAMS",

    DEBOUNCE_INPUT_TIMEOUT: 800,
    DEBOUNCE_INPUT_TIMEOUT_LONG: 1600,

    //in _variables.scss, config.js and plugins/vuetify.js

    WIDTH_SM: 744,
    WIDTH_MD: 1280,
    WIDTH_LG: 1838,
    WIDTH_XL: 1920,

    USER_PROJECT_STATUS_SUBMITTED: 'На рассмотрении',
    USER_PROJECT_STATUS_REGISTERED: 'Зарегистрирован',
    USER_PROJECT_STATUS_REALIZED: 'Реализован',

    MAPBOX: {
        accessToken: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2w0NGdkbWhrMDMzdzNkbzJudGU3aHIyZCJ9.aCdgbN39Uloe8_MjL2y1Xw",
        // /style: "mapbox://styles/playnext/cjqr7cp2i36ip2rqilaiuxmq3",
        style: "mapbox://styles/playnext/cl9n5pfv9002415mg3lkw5421",
        styleEN: "mapbox://styles/playnext/cldu9eqdp00bg01qqku9rq19u"
    },

    YANDEX_MAP_API_KEY: "e26ae0eb-d93a-4eb4-85d6-699b565c1a24",

    // todo remove hardcode
    PRICE_ID_BASE: "1",
    //PRICE_ID_OPTPRICE: "2",

    LANGUAGES: [
        {
            name:"Русский",
            mobileName:"ру",
            code:"ru",
            host: "https://lighting.vitrulux.com"
        },
        {
            name:"English",
            mobileName:"en",
            code:"en",
            host: "https://en.lighting.vitrulux.com"
        },
    ],

    IS_PRICES_SHOWN: false, // свойство, по которому показываются или скрываются цены на всех страницах проекта
}
